import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { OrderSummary } from "src/app/shared/models/ordersummary";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { getWindow } from "ssr-window";
import { Product } from "../../../../shared/models/product";
import { ProductService } from "../../../../shared/services/product.service";
import { PinDialogComponent } from "../pin-dialog/pin-dialog.component";
import { ProductThumbnail } from "src/app/shared/models/product-thumbnail";
import { ProductDesignDialogComponent } from "../product-design-dialog/product-design-dialog.component";
import { WebUserInfo } from "src/app/shared/models/web-user-info";
import { CommonService } from "src/app/shared/services/CommonService";
import { ProductsWithStock } from "src/app/shared/models/products-with-stock";
import { HttpErrorResponse } from "@angular/common/http";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { OfferDetailsDialogComponent } from "../offer-details-dialog/offer-details-dialog.component";
import { BulkDiscountDto } from "src/app/shared/models/bulkdiscountdto";
import { OfferDialogComponent } from "../offer-dialog/offer-dialog.component";
import { StorePickupDialogComponent } from "../store-pickup-dialog/store-pickup-dialog.component";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";

@Component({
  selector: "app-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.scss"],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  private sub: any;
  product: Product;
  webUrl: string;
  pincode: any;
  productCode: number;
  productWithOffers: ProductsWithWebOrderOffers[] = [];

  orderSummary: OrderSummary = new OrderSummary();

  currentUrl: string;
  descStr: string = '';

  productThumbnails: ProductThumbnail[] = [];
  thumbNailImgList: string[] = [];
  productImgName: string = '';
  productVideoName: string = '';
  thumnnailType: string;
  sharingUrl: string = '';
  offerNames: string = '';
  designProducts: Product[] = [];
  productWithStock: ProductsWithStock[] = [];

  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;
  offerObj: any;

  serverUrl: string;

  deliveryType: string;
  googleCustomer: GoogleCustomer;

  //Subscription
  private productSubcription: Subscription;
  private offerSubscription: Subscription;
  private getDesignSubscription: Subscription;
  private saveWebUserInfoSubscription: Subscription;
  private checkAllowStockSubscription: Subscription;
  private stockSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private productService: ProductService,
    private toastrService: ToastService,
    private commonService: CommonService,
    private navRoute: Router,
    private apiFactory: APIFactoryEndPoints,
    private pincodeDialogService: PinDialogService
  ) {
    this.serverUrl = this.apiFactory.getServerUrl;
    this.product = new Product();
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname
    this.webUrl = environment.cartApiUrl;
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.sub = this.route.params.subscribe((params) => {
      const id = params?.["id"]; // (+) converts string 'id' to a number
      this.getProductDetail(id);
    });
    // this.getOfferDetail();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getProductDetail(id: number) {
    this.productCode = id;
    this.productSubcription = this.productService.getProductById(id).subscribe((data) => {
      this.product = data;

      if (this.product.description)
        this.descStr = "<li>" + this.product.description.replace('. ', ".<li>");

      this.sharingUrl = getWindow().location.origin + '/product' + '/' + this.product.product_Code;

      this.productImgName = this.product.product_Picture;
      this.thumnnailType = 'image';

      this.productThumbnails = [];
      let thumbnail: ProductThumbnail = new ProductThumbnail();
      thumbnail.fileName = this.product.product_Picture;
      thumbnail.fileType = "image";
      this.productThumbnails.push(thumbnail);
      this.product.productThumbnails.forEach(element => {
        this.productThumbnails.push(element);
      });
      console.log(data);
      this.getOfferDetail();
      this.saveWebUserInfo();
    });
  }

  getOfferDetail() {
    this.pincode = JSON.parse(this.localStorage.getItem("pincode_item"));
    console.log(this.pincode);

    let locId: number = JSON.parse(this.localStorage.getItem("location_code")) || 0;
    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    if (!type) {
      this.fetchOfferDetails(locId);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type)
      .subscribe((data: string) => {
        if (data === 'Not Available') {
          console.log("No offers available.");
          return;
        }

        locId = data === 'allowForWebsite' ? this.googleCustomer?.locationCode || 0 : data === 'allowForLocation'
          ? JSON.parse(this.localStorage.getItem('location_code')) || 0 : 0;

        this.fetchOfferDetails(locId);
      });
  }

  fetchOfferDetails(locId: number) {
    this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, [this.productCode], this.currentUrl)
      .subscribe((data) => {
        if (data.length === 0) return;

        this.productWithOffers = data;
        console.log(data);

        this.offerNames = this.productWithOffers[0].offerDetails.map(element => element.schemeName).join(', ');

        let offerData = this.productWithOffers.find(offer => offer.productCode === this.product.product_Code);
        if (offerData) {
          this.product.offerDetails = offerData.offerDetails;
          this.product.offers = offerData.offers;
        }
      });
  }

  openDialog(product: Product) {
    this.orderSummary.product = this.product;
    this.pincode = this.localStorage.getItem("pincode_item");
    this.orderSummary.pincode = this.pincode;
    console.log(this.orderSummary);
    this.pincodeDialogService.setOrderSummary(this.orderSummary);
    this.navRoute.navigate(["/address/address-app"]);
  }

  specialProductCodes: number[] = [901, 902, 743, 744, 746, 747, 745, 742];
  openDeliveryTypeDialog(product: Product, goToCartPage: boolean) {
    if (this.specialProductCodes.includes(Number(product.product_Code))) {
      this.toastrService.showWarningToast('Coming soon.');
      return;
    }
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    let locId = JSON.parse(this.localStorage.getItem('location_code'));
    console.log(deliveryType)
    if (!deliveryType || !locId) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.setStorePickup(product, goToCartPage);
          } else if (result === 'delivery') {
            this.setDelivery(product, goToCartPage);
            // this.openPinDialog(product, goToCartPage);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.setStorePickup(product, goToCartPage);
      } else if (deliveryType === 'delivery') {
        this.setDelivery(product, goToCartPage);
        // this.openPinDialog(product, goToCartPage);
      }
    }
  }

  setStorePickup(product: Product, goToCartPage: boolean) {
    let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
    if (product.shelf_Life_In_Hours >= selfLife) {
      product.cartProductType = 'Product';
      this.getDesignBySubGroupAndUrl(product, goToCartPage);
    } else {
      this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
    }
  }

  setDelivery(product: Product, goToCartPage: boolean) {
    let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
    if (product.shelf_Life_In_Hours >= selfLife) {
      product.cartProductType = "Product";
      this.getProductWithStock(product, goToCartPage);
    } else {
      this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
    }
  }

  addToCart(product: Product) {
    product.cartProductType = "Product";
    // const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item"));
    // if (products[0]?.cartProductType == 'Product' || products[0]?.cartProductType == null) {
    //   this.productService.addToCart(product);
    // }
    // else {
    //   this.toastrService.showErrorToast('Check out of Make To Order cart as Product item can not be added to Make To Order cart');
    // }
    this.productService.addToCart(product);
  }

  openPinDialog(product: Product, goToCartPage: boolean) {
    this.pincode = JSON.parse(this.localStorage.getItem("pincode_item"));
    console.log(this.pincode);
    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        data: product,
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          // this.getGroupList();
          let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
          if (product.shelf_Life_In_Hours >= selfLife) {
            product.cartProductType = "Product";
            // this.productService.addToCart(product);
            // this.getDesignBySubGroupAndUrl(product);
            this.getProductWithStock(product, goToCartPage);
          } else {
            this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
          }
        }
      });
    } else {
      let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
      if (product.shelf_Life_In_Hours >= selfLife) {
        product.cartProductType = "Product";
        // this.productService.addToCart(product);
        // this.getDesignBySubGroupAndUrl(product);
        this.getProductWithStock(product, goToCartPage);
      } else {
        this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
      }
    }
  }

  getProductWithStock(product: Product, goToCartPage: boolean) {
    let locId = JSON.parse(this.localStorage.getItem("location_code"));
    if (locId) {
      this.checkAllowStockSubscription = this.productService.checkAllowStockByLocAndUrl(this.apiFactory.checkAllowStockByLocAndUrl, locId, this.currentUrl).subscribe((data: any) => {
        if (data) {
          if (product.product_Code) {
            this.stockSubscription = this.productService.getStockDetails(this.apiFactory.getStockDetails, [product.product_Code], locId).subscribe((data: any) => {
              this.productWithStock = data;

              var arr = this.productWithStock.filter((p) => p.product_Code == product.product_Code);
              if (arr.length != 0) {
                product.systemStock = arr[0].systemStock;
              }
              if (product.cartProductType == 'Product' && product.minimum_Order_Quantity > product.systemStock) {
                this.toastrService.showErrorToast(product.product_Name + " is out of stock, stock available " + product.systemStock);
                return;
              }
              this.getDesignBySubGroupAndUrl(product, goToCartPage);
            },
              (error: HttpErrorResponse) => {
                this.toastrService.showErrorToast("Something wrong..");
              });
          } else {

          }
        } else {
          this.getDesignBySubGroupAndUrl(product, goToCartPage);
        }
      },
        (error: HttpErrorResponse) => {
          this.toastrService.showErrorToast("Something wrong..");
        })
    } else {
      this.toastrService.showErrorToast("Location can't place order");
    }

  }

  getDesignBySubGroupAndUrl(product: Product, goToCartPage: boolean) {
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
        return;
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is already added to cart');
        return;
      }
    }
    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, product.productSubGroup.refNo, this.currentUrl).subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        this.designProducts = data;
        const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
        let designIndex = cartProducts.findIndex(e => e.designForProductCode === product.product_Code);
        if (designIndex >= 0) {
          this.designProducts.map(e => e.is_Default = false);
          this.designProducts.filter(item => item.product_Code == cartProducts[designIndex].product_Code).map(e => e.is_Default = true);
        }
        this.openDesignDialog(product, this.designProducts, goToCartPage);
      } else {
        if (product.offerDetails?.length > 0)
          this.openOfferDialog(product, null, goToCartPage)
        else {
          this.productService.addToCart(product);

          if (goToCartPage) {
            //Added to redirect to cart
            this.productService.navigateToCart(product);
          }
        }
        // this.toastrService.showErrorToast('No design available');
      }
    });
  }

  openDesignDialog(product: Product, designProducts: Product[], goToCartPage: boolean) {
    // let productList: Product[] = [];
    // productList.push(product);
    document.body.classList.add('dialog-open');
    const dialogRef = this.dialog.open(ProductDesignDialogComponent, {
      width: "430px",
      height: "410px",
      maxWidth: "90vw",
      data: designProducts,
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      document.body.classList.remove('dialog-open');
      if (result) {
        console.log(result.data)
        if (result?.data) {
          let design: Product = result?.data;
          design.designForProductCode = product.product_Code;
          design.cartProductType = "Product";
          // productList.push(design)
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, design, goToCartPage)
          else {
            this.productService.addToCartDesign(product, design);

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
        } else if (result?.noDesign) {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null, goToCartPage)
          else {
            this.productService.addToCart(product);

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
        }
      }
    });
  }

  openOfferDialog(product: Product, design: Product, goToCartPage: boolean) {
    console.log(product)
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
        return;
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is already added to cart');
        return;
      }
    }
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    let appliedOfferId: string = product.offerId ? product.offerId : product.offerDetails[0].offerId;
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: "500px",
      height: "300px",
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = offerObj.minQty;
          console.log(product);
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
          if (product.cartProductType == "Product") {
            product.basePremiumFlavourPrice = 0;
            product.premiumFlavourPrice = 0;
          }
        }
        console.log(product);

        if (design) {
          this.productService.addToCartDesign(product, design);
        }
        else if (product.offerDetails[0].minQty > 1) {
          this.productService.addToCart(product);
        }

        if (offerObj.schemeType == "Buy 1 get 1 Free" || offerObj.schemeType == "Buy X get Y Free") {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                // offer processing
                this.productOffer = data;
                //set order qty as product QTY IN CART
                this.productOffer.product_Name = this.productOffer.product_Name.concat("(").concat(offerObj.schemeType).concat(")");
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.hsn_Code = this.productOffer.hsn_Code;

                // main product tax and net amount calculations
                product.discountAmt = 0;
                product.discountRate = 0;
                var taxableAmt = product.sale_Price - (product.sale_Price * 0) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                console.log(product);
                this.productService.addToCartWithOffer(product, this.productOffer, design);

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                }
              }
            );
        } else {
          if (offerObj.schemeType == "Buy x get x percent off") {
            this.productDiscountSubscription = this.productService.getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  this.discountOffer = data;
                  product.discountAmt = (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt = product.sale_Price - (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  this.productService.addToCartWithOffer(product, null, design);

                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt = (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt = product.sale_Price - (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithOffer(product, null, design);

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              });
          }
        }
      } else {
        // if (design)
        //   this.productService.addToCartDesign(product, design);
        // else
        //   this.productService.addToCart(product);
      }
    });
  }

  viewOfferDetail() {
    console.log(this.productWithOffers[0])
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: this.productWithOffers[0]?.offerDetails }
    });

    dialogRef.afterClosed().subscribe(res => {

    })
  }

  onthumbNailimgClick(thumb: ProductThumbnail) {
    console.log(thumb)
    this.thumnnailType = thumb.fileType;
    if (thumb.fileType == 'image')
      this.productImgName = thumb.fileName;
    else
      this.productVideoName = thumb.fileName;

  }

  onShareClick(product: Product) {
    var url = 'https://web.whatsapp.com/send'
    var text = getWindow().location.origin + '/product' + '/' + product.product_Code;
    console.log(text)
    window.open(url + '?text=' + text)
  }

  saveWebUserInfo() {
    let localWebUserInfo: WebUserInfo = JSON.parse(this.localStorage.getItem("web_user_info"));
    if (localWebUserInfo) {
      localWebUserInfo.productCode = this.productCode;
      this.saveWebUserInfoSubscription = this.commonService.saveWebUserInfo(this.apiFactory.inserWebUserInfo, localWebUserInfo).subscribe((data) => {
        if (data) {
        }
      });
    }
  }
}
